<template>
  <div class="curve">
    <img src="../assets/images/blob_leaf.svg" alt="leaf" class="blob-leaf" />
    <img src="../assets/images/blob_block.svg" alt="block1" class="blob-block" />
    <div class="custom-container">
      <v-row>
        <v-col cols="12" md="6" lg="8" class="my-auto">
          <div class="overline font-weight-medium">
            Make <span class="fg-color font-weight-bold">bangladesh</span> popular once again
          </div>
          <div class="hero-text primary-font">
            Connecting Local Companies With Suppliers From The World
          </div>
          <v-btn
            depressed
            class="ttn bg-color white--text mt-12 px-8 py-6 primary-font custom-radius"
            @click="$scrollTop('brand')"
            >Learn More</v-btn
          >
        </v-col>
        <v-col cols="12" md="6" lg="4" class="mx-auto">
          <v-img src="../assets/images/hero-image.svg"></v-img>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.curve {
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: rgba(51, 148, 0, 0.15);
  background-size: cover;
  clip-path: circle(180vh at 50% -80vh);
  .blob-leaf {
    position: absolute;
    left: 3%;
    top: 12%;
    z-index: -1;
  }
  .blob-block {
    position: absolute;
    right: -3%;
    top: 50%;
    z-index: -1;
  }
}

.hero-text {
  font-size: 3.5em;
  font-weight: 700;
}
.all-product {
  height: 500px;
}

@media screen and (max-width: 960px) {
  .hero-text {
    font-size: 3em;
    font-weight: 800;
  }
}

@media screen and (max-width: 480px) {
  .hero-text {
    font-size: 2.3em;
    font-weight: 600;
  }
  .blob-block {
    display: none;
  }
  .blob-leaf {
    display: none;
  }
}
</style>
