import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { firestorePlugin } from "vuefire";

Vue.use(firestorePlugin);
Vue.config.productionTip = false;
Vue.prototype.$scrollTop = function(section) {
  var element = document.getElementById(section);
  var top = element.offsetTop;
  window.scrollTo(0, top);
};

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
