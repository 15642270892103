<template>
  <v-app>
    <navbar />
    <v-main>
      <router-view></router-view>
    </v-main>
    <common-footer />
  </v-app>
</template>

<script>
import CommonFooter from "./components/CommonFooter.vue";
import Navbar from "./components/Navbar.vue";

export default {
  name: "App",

  components: {
    Navbar,
    CommonFooter,
  },

  data: () => ({
    //
  }),
};
</script>

<style lang="scss">
$primary-color: #339400;
body {
  overflow: hidden;
}
.fg-color {
  color: $primary-color !important;
}
.bg-color {
  background-color: $primary-color !important;
}
.primary-font {
  font-family: "Poppins", sans-serif;
}
.custom-container {
  padding: 50px 100px;
  position: relative;
}

.custom-radius {
  border-radius: 8px;
}

.heading {
  color: $primary-color;
  font-weight: 700;
  font-size: 4em;
}
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-thumb {
  background: #bebebe;
  border-radius: 4px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

@media screen and (max-width: 768px) {
  .custom-container {
    padding: 20px 50px;
  }
}
@media screen and (max-width: 480px) {
  .custom-container {
    padding: 10px 15px;
  }
  .heading {
    color: $primary-color;
    font-weight: 700;
    font-size: 2em;
  }
}
</style>
