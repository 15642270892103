<template>
  <div>
    <hero-section />
    <branding-cards />
    <about-us />
  </div>
</template>

<script>
import AboutUs from "../components/AboutUs.vue";
import BrandingCards from "../components/BrandingCards.vue";
import HeroSection from "../components/HeroSection.vue";

export default {
  name: "Home",
  components: { HeroSection, BrandingCards, AboutUs },
};
</script>
