<template>
  <div class="custom-container">
    <v-row>
      <v-col cols="12" md="6" lg="8">
        <div class="heading primary-font">
          About Us
        </div>
        <div class="body1 primary-font">
          Bareleaf is Canadian company with a mission to connect local businesses with international suppliers. Our
          clients enjoy the best prices, access to our strong network of vendors and seamless logistics. All of our
          products are manufactured in Bangladesh, a country known for supplying the highest quality products and raw
          materials. We take pride in our value proposition – Delivering a unique combination of competitive pricing and
          unparalleled client experience.
        </div>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-img contain src="../assets/images/about_us.svg" height="400"></v-img>
      </v-col>
    </v-row>
    <v-row class="my-12">
      <v-col cols="12">
        <v-img
          contain
          src="https://firebasestorage.googleapis.com/v0/b/bareleaf-admin.appspot.com/o/World-rafiki%201.svg?alt=media&token=3797b6b8-3983-43cf-bab0-e9ace87f657b"
        >
          <template v-slot:placeholder>
            <v-sheet color="grey lighten-4">
              <v-skeleton-loader class="mx-auto my-auto" type="image"></v-skeleton-loader>
            </v-sheet>
          </template>
        </v-img>
      </v-col>
    </v-row>
    <v-row class="my-12">
      <v-col cols="12" md="6">
        <v-img contain src="../assets/images/idea.svg" height="400"></v-img>
      </v-col>
      <v-col cols="12" md="6">
        <div class="body1 primary-font">
          Bareleaf is Canadian company with a mission to connect local businesses with international suppliers. Our
          clients enjoy the best prices, access to our strong network of vendors and seamless logistics. All of our
          products are manufactured in Bangladesh, a country known for supplying the highest quality products and raw
          materials. We take pride in our value proposition – Delivering a unique combination of competitive pricing and
          unparalleled client experience.
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.body1 {
  line-height: 212%;
  text-align: justify;
}
</style>
