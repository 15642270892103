import { render, staticRenderFns } from "./HeroSection.vue?vue&type=template&id=4668ed19&scoped=true&"
import script from "./HeroSection.vue?vue&type=script&lang=js&"
export * from "./HeroSection.vue?vue&type=script&lang=js&"
import style0 from "./HeroSection.vue?vue&type=style&index=0&id=4668ed19&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4668ed19",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VImg,VRow})
