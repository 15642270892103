<template>
  <div class="custom-container" id="brand">
    <img src="../assets/images/blob_block.svg" alt="block" class="blob-block" />
    <v-row>
      <v-col cols="12" sm="6" lg="4">
        <v-card rounded="lg" class="card-style">
          <v-img src="../assets/images/raw_material.svg" contain max-height="150" class="card-image"></v-img>
          <v-card-title class="d-flex justify-center primary-font">Collect raw materials</v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" lg="4">
        <v-card rounded="lg" class="card-style">
          <v-img src="../assets/images/marterial_processing.svg" contain max-height="150" class="card-image"></v-img>
          <v-card-title class="d-flex justify-center primary-font">Process materials</v-card-title>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" lg="4">
        <v-card rounded="lg" class="card-style">
          <v-img src="../assets/images/material_export.svg" contain max-height="150" class="card-image"></v-img>
          <v-card-title class="d-flex justify-center primary-font">Ready to export</v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.blob-block {
  position: absolute;
  left: -2%;
  top: 70%;
}
.card-style {
  box-shadow: none !important;
  transition: all 0.3s;
  &:hover {
    // -webkit-box-shadow: 0px 0px 15px 10px rgba(188, 188, 188, 0.59);
    // box-shadow: 0px 0px 15px 10px rgba(188, 188, 188, 0.59);
    box-shadow: 1px 1px 0px rgb(209, 209, 209), 2px 2px 0px rgb(209, 209, 209), 3px 3px 0px rgb(209, 209, 209),
      4px 4px 0px rgb(209, 209, 209), 5px 5px 0px rgb(209, 209, 209), 6px 6px 0px rgb(209, 209, 209) !important;
    .card-image {
      position: relative;
      animation: myanimation 1.5s infinite alternate ease-in-out;

      @keyframes myanimation {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(0.8);
        }
      }
    }
  }
}
</style>
